import React from "react";
import { Footer } from "@lexdania/components";
import { IFooterFetchers, FooterReducerActions, IExternalLinks } from "./../store/footer/footerReducer";
import { AppState } from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

interface IFooterContainerProps extends IFooterFetchers {
  externalLinks: IExternalLinks;
}

class FooterContainer extends React.Component<IFooterContainerProps, {}> {
  public componentDidMount(): void {
    this.props.getExternalLinks();
  }

  public render() {
    const wasLink = this.props.externalLinks.wasLink;
    const bottomLinks = [
      { text: "Om Lovtidende", url: "/about" },
      { text: "Kontakt", url: "/contact" },
      { text: "FAQ", url: "/faq" },
      { url: wasLink.url, text: wasLink.text, isExternal: true },
    ];
    const listLinks = this.props.externalLinks.listLinks;
    return (
      <Footer aboutHeader="Om" bottomLinks={bottomLinks} externalLinks={listLinks}>
        Lovtidende A, B og C er de publikationer, hvori regeringen foretager den officielle kundgørelse af nye love og regler. Lovtidende udgives af
        Civilstyrelsen.
        <br />
        På lovtidende.dk anvendes der ikke cookies.
      </Footer>
    );
  }
}

export default connect(
  ({ footer }: AppState, ownProps: any) => ({
    ...ownProps,
    ...{ externalLinks: footer.externalLinks },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...FooterReducerActions,
      },
      dispatch
    )
)(FooterContainer);
