import * as React from "react";
import { Helmet } from "react-helmet";
import { Alert } from "reactstrap";

const GlobalErrorContainer: React.FC = () => {
  document.title = "Lovtidende | Fejl";

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Alert color="danger">
        <h1>Vi beklager!</h1>
        <p>Der er desværre sket en uventet systemfejl på siden.</p>
        {/* Intented use of href here - we want to reload on error */}
        <p>
          Venligst gå til <a href="/">forsiden</a>.
        </p>
      </Alert>
    </>
  );
};

export default GlobalErrorContainer;
