import { createAction, ActionsUnion } from "../actionHelper";
import { IProclamationBatch } from "../../models/IProclamationBatch";
import { Dispatch } from "react";
import { apiGET } from "../api";
import { PublicationMediaEnum } from "../../models/publicationMediaEnum";

// Actions
export enum ProclamationActionEnum {
  RECEIVE_PROCLAMATION = "RECEIVE_PROCLAMATION",
  FETCHING_DATA = "FETCHING_DATA",
}

export interface IProclamationState {
  publicationMedia: PublicationMediaEnum;
  batchList: IProclamationBatch[];
  fetchingData: boolean;
}

const receiveResult = (result: IProclamationState) => createAction(ProclamationActionEnum.RECEIVE_PROCLAMATION, result);
const fetchingData = () => createAction(ProclamationActionEnum.FETCHING_DATA);
export const Actions = { receiveResult, fetchingData };
type ProclamationActions = ActionsUnion<typeof Actions>;

// Fetch
const getLatest = (publicationMediaId: PublicationMediaEnum) => async (dispatch: Dispatch<ProclamationActions>) => {
  // Flip the fetching data flag
  dispatch(fetchingData());
  const result = await apiGET<IProclamationBatch[]>(`api/proclamation/latest/${publicationMediaId}`);

  // push the result to redux
  dispatch(
    receiveResult({
      publicationMedia: publicationMediaId,
      batchList: result,
      fetchingData: false,
    })
  );
};

export interface IProclamationReducerActions {
  getLatest(publicationMediaId: PublicationMediaEnum): Promise<void>;
}

export const ProclamationReducerActions = {
  getLatest,
};

// Reducer
const defaultState: IProclamationState = {
  publicationMedia: PublicationMediaEnum.LOVTIDENDE_A,
  batchList: [],
  fetchingData: true,
};

export default (state: IProclamationState = defaultState, action: ProclamationActions): IProclamationState => {
  switch (action.type) {
    case ProclamationActionEnum.RECEIVE_PROCLAMATION: {
      return action.payload;
    }

    case ProclamationActionEnum.FETCHING_DATA: {
      return { ...state, fetchingData: true };
    }

    default:
      return state;
  }
};
