import { createAction, IActionWithPayload } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "..";
import { IRessort } from "../../models/IRessort";
import { IRessortCollection } from "../../models/IRessortCollection";

const RECEIVE_RESSORTER = "RECEIVE_RESSORTER";

// API
const GETActiveRessorter = () => apiGET<IRessort[]>("api/ressort");
const GETAllRessorter = () => apiGET<IRessort[]>("api/ressort/all");

const receiveRessorter = (ressorter: IRessortCollection) => createAction(RECEIVE_RESSORTER, ressorter);

const getRessorter = () => async (dispatch: any, getState: () => AppState) => {
  const { ressorter } = getState();
  if (ressorter.active.length === 0) {
    const activeRessortResult = await GETActiveRessorter();
    const allRessortResult = await GETAllRessorter();
    const ressortCollection: IRessortCollection = {
      active: activeRessortResult,
      all: allRessortResult,
    };
    dispatch(receiveRessorter(ressortCollection));
  }
};

const initialState: IRessortCollection = {
  active: [],
  all: [],
};

// Actions Interface
export interface IRessortActions {
  getRessorter: () => void;
}

export const RessortActions: IRessortActions = {
  getRessorter,
};

// Reducer function
export default (state: IRessortCollection = initialState, action: IActionWithPayload<string, IRessortCollection>): IRessortCollection => {
  switch (action.type) {
    case RECEIVE_RESSORTER:
      return { ...action.payload };
    default:
      return state;
  }
};
