import * as React from "react";
import { connect } from "react-redux";
import { ITextState, ITextFetchers, TextReducerActions } from "../../store/texts/textsReducer";
import { AppState } from "../../store";
import { TextType } from "../../models/TextType";
import { bindActionCreators } from "redux";
import "./TextContainer.scss";

interface ITextContainerProps extends ITextFetchers {
  type: TextType;
  allTexts: ITextState;
  title: string;
}

class TextContainer extends React.Component<ITextContainerProps, {}> {
  public componentDidMount() {
    document.title = this.props.title;
    this.props.getText(this.props.type);
  }

  public componentDidUpdate() {
    document.title = this.props.title;
    this.props.getText(this.props.type);
  }

  public render() {
    const { allTexts, type } = this.props;
    const html = allTexts[type];
    const htmlToRender = html !== undefined ? html : "";
    return (
      <div className="row mt-0 mt-md-5 text-container">
        <div className="col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 clearfix">
          <section className="contact" dangerouslySetInnerHTML={{ __html: htmlToRender }} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ texts }: AppState, ownProps: any) => ({
    ...ownProps,
    ...{ allTexts: texts },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...TextReducerActions,
      },
      dispatch
    )
)(TextContainer);
