import { IAargangState } from "../../models/IAargangState";
import { createAction, ActionsUnion } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { AppState } from "../index";
import { IAargangOgRessorter } from "../../models/IAargangOgRessorter";

// Action Constants
const RECEIVE_AARGANGE = "RECEIVE_AARGANGE";

const receiveAargange = (payload: IAargangOgRessorter[]) => createAction(RECEIVE_AARGANGE, payload);
const actions = { receiveAargange };
type AargangActions = ActionsUnion<typeof actions>;

// Fetch
const getMediaData = (mediaId: number) => async (dispatch: Dispatch<AargangActions>, getState: () => AppState) => {
  const state: IAargangState = getState().aargangState;
  if (state.publicationMediaSelectorList.find(p => p.publicationMedia.id === mediaId)) {
    return; // Already found in state
  }
  const payload = await apiGET<IAargangOgRessorter[]>(`api/volumes/${mediaId}`);
  dispatch(receiveAargange(payload));
};

export interface IAargangReducerActions {
  getMediaData(mediaId: number): Promise<void>;
}

export const AargangReducerActions = {
  getMediaData,
};

export const defaultAargangState: IAargangState = {
  publicationMediaSelectorList: [],
};

// Reducer
export default (state: IAargangState = defaultAargangState, action: AargangActions): IAargangState => {
  switch (action.type) {
    case RECEIVE_AARGANGE: {
      return {
        ...state,
        publicationMediaSelectorList: state.publicationMediaSelectorList.concat({
          publicationMedia: action.payload[0].publicationMedia,
          aargangOgRessorter: action.payload,
        }),
      };
    }
    default:
      return state;
  }
};
