import { createAction, IActionWithPayload } from "../actionHelper";
import { ParsedParams, parseWithDefaults, parseQueryString } from "@lexdania/components";

// Action Constants
const SET_PARAMS = "SET_PARAMS";

export type DocumentSearchParams = {
  page: number; // active page
  ps: number; // active page size
  o: number; // selected order by value
  d: boolean; // sort direction
  yh: number; // year high
  yl: number; // year low
  r: string[]; // ressort
  t: string[]; // search terms
  dt: string[]; // document type
  ttl: string[]; // title
  pttl: string[]; // popular title
  hr: string[]; // historical ressort
  st: string; // start time?
  et: string; // end time?
  dnr: string; // document number range
  e: string[]; // emneord
};

export interface IDocumentSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: DocumentSearchParams) => DocumentSearchParams;
}

// Action Exports
export interface IDocumentSearchActions {
  setParams: (params: string) => void;
}

export const DocumentSearchActions: IDocumentSearchActions = {
  setParams: (params: string) => createAction(SET_PARAMS, params),
};

const defaultState: IDocumentSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray
};

export default (state: IDocumentSearchState = defaultState, action: IActionWithPayload<string, string>): IDocumentSearchState => {
  switch (action.type) {
    case SET_PARAMS: {
      const params = action.payload; //.replace(/^\?/, "");
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    }
    default:
      return state;
  }
};
