import * as React from "react";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import Layout from "./components/Layout";
const Home = lazy(() => import("./components/Home"));
const VolumeSearch = lazy(() => import("./components/VolumeSearch"));
const DocumentsContainer = lazy(() => import("./containers/documents/DocumentsContainer"));
const Subscribe = lazy(() => import("./containers/subscribe/Subscribe"));
const Unsubscribe = lazy(() => import("./containers/unsubscribe/Unsubscribe"));
const NotFoundContainer = lazy(() => import("./containers/error/NotFoundContainer"));
import GlobalErrorContainer from "./containers/error/GlobalErrorContainer";
import TextContainer from "./containers/text/TextContainer";
const FaqContainer = lazy(() => import("./containers/FaqContainer"));
import { TextType } from "models/TextType";
const Reconfirm = lazy(() => import("containers/subscribe/Reconfirm"));

export const routes = (
  <Layout>
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/volumesearch" component={VolumeSearch} />
        <Route path="/about">
          <TextContainer type={TextType.About} title="Lovtidende | Om" />
        </Route>
        <Route path="/contact">
          <TextContainer type={TextType.Contact} title="Lovtidende | Kontakt" />
        </Route>
        <Route path="/faq" component={FaqContainer} />
        <Route path="/documents/reconfirm" component={Reconfirm} />
        <Route path="/documents/subscribe" component={Subscribe} />
        <Route path="/documents/unsubscribe" component={Unsubscribe} />
        <Route path="/documents" component={DocumentsContainer} />
        <Route path="/error" component={GlobalErrorContainer} />
        <Route path="*" component={NotFoundContainer} />
      </Switch>
    </Suspense>
  </Layout>
);
