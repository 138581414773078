import React, { Component } from "react";
import { Header, TopMenu, TopMenuItem, IconType } from "@lexdania/components";

export class HeaderContainer extends Component {
  render() {
    return (
      <div>
        <Header logoText={"lovtidende.dk"}>
          <TopMenu>
            <TopMenuItem className="header-home" icon={IconType.Home} text="Forside" url="/" />
            <TopMenuItem icon={IconType.City} text={"Årgange og Ministerier"} url="/volumesearch" />
            <TopMenuItem icon={IconType.Search} text="Søg" url="/documents" />
          </TopMenu>
        </Header>
      </div>
    );
  }
}
