import { ActionsUnion, createAction, IActionWithPayload } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { IMaintenanceMessage } from "../../models/IMaintenanceMessage";

// Action Constants
const SET_OK_CLICKED = "SET_OK_CLICKED";
const GET_MAINTENANCE_MESSAGES = "GET_MAINTENANCE_MESSAGES";

export interface IMaintenanceMessagesState {
  messages: IMaintenanceMessage[];
  isMessagesSeen: boolean;
}

// External action methods
const setOkClicked = () => createAction(SET_OK_CLICKED);

// Local actions
const setMaintenanceMessages = (messages: IMaintenanceMessage[]) => createAction(GET_MAINTENANCE_MESSAGES, messages);

const getMaintenanceMessages = () => async (dispatch: Dispatch<LocalActions>) => {
  const messages = await apiGET<IMaintenanceMessage[]>("api/maintenance/messages");
  dispatch(setMaintenanceMessages(messages));
};

// External actions
export interface IMaintenanceMessagesActions {
  setOkClicked: () => void;
  getMaintenanceMessages: () => void;
}

export const MaintenanceMessagesActions: IMaintenanceMessagesActions = {
  setOkClicked: setOkClicked,
  getMaintenanceMessages: getMaintenanceMessages,
};

const Actions = { setMaintenanceMessages };
type LocalActions = ActionsUnion<typeof Actions>;

const defaultState: IMaintenanceMessagesState = {
  messages: [],
  isMessagesSeen: false,
};

export default (state: IMaintenanceMessagesState = defaultState, action: IActionWithPayload<string, IMaintenanceMessage[]>): IMaintenanceMessagesState => {
  switch (action.type) {
    case SET_OK_CLICKED:
      return { ...state, ...{ isMessagesSeen: true } };
    case GET_MAINTENANCE_MESSAGES:
      return { ...state, ...{ messages: action.payload } };
    default:
      return state;
  }
};
