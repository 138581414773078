import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import aargangState from "./aargang/aargangReducer";
import documentSearch from "./documents/documentSearchReducer";
import proclamation from "./proclamation/proclamationReducer";
import maintenanceMessagesReducer from "./maintenance/maintenanceMessagesReducer";
import ressorter from "./ressorter/ressortReducer";
import texts from "./texts/textsReducer";
import footer from "./footer/footerReducer";
import tags from "./tags/tagsReducer";
import notes from "./notes/notesReducer";
import faqAreas from "./faq/faqReducer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  aargangState,
  documentSearch,
  proclamation,
  maintenanceMessagesReducer,
  ressorter,
  texts,
  footer,
  tags,
  notes,
  faqAreas,
});

export type AppState = ReturnType<typeof rootReducer>;
export default function configureStore() {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...[thunk]), (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f)
  );

  return store;
}
