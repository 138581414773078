import { createAction, IActionWithPayload, ActionsUnion } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { ITagSectionList } from "../../models/ITagSectionList";

const RECEIVE_TAG = "RECEIVE_TAG";

const GETTags = () => apiGET<ITagSectionList>("api/tags");

const receiveTags = (ressort: ITagSectionList) => createAction(RECEIVE_TAG, ressort);

const getTags = () => async (dispatch: Dispatch<TagActionTypes>) => {
  const tagResult = await GETTags();
  dispatch(receiveTags(tagResult));
};

const Actions = { receiveTags };
type TagActionTypes = ActionsUnion<typeof Actions>;

export interface ITagActions {
  getTags: () => void;
}

export const TagActions: ITagActions = {
  getTags,
};

export default (
  state: ITagSectionList = { lovA: [], lovCBilateral: [], lovCMultilateral: [] },
  action: IActionWithPayload<string, ITagSectionList>
): ITagSectionList => {
  switch (action.type) {
    case RECEIVE_TAG:
      return action.payload;
    default:
      return state;
  }
};
