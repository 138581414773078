import * as React from "react";
import { MaintenanceBanner } from "@lexdania/components";
import { connect } from "react-redux";
import { AppState } from "../store";
import { IMaintenanceMessagesActions, MaintenanceMessagesActions } from "../store/maintenance/maintenanceMessagesReducer";
import { bindActionCreators } from "redux";
import { IMaintenanceMessage } from "../models/IMaintenanceMessage";
import "./MaintenanceContainer.scss";

interface IProps extends IMaintenanceMessagesActions {
  messages: IMaintenanceMessage[];
  isMessagesSeen: boolean;
}

class MaintenanceContainer extends React.Component<IProps, {}> {
  public componentDidMount() {
    this.props.getMaintenanceMessages();
  }

  public onOkClick = () => {
    this.props.setOkClicked();
  };

  public render() {
    const { messages, isMessagesSeen } = this.props;

    if (messages && messages.length <= 0) {
      return null;
    }

    if (isMessagesSeen) {
      return null;
    }

    return (
      <MaintenanceBanner onOkClicked={this.onOkClick}>
        {messages.map((m, i) => (
          <div key={i}>
            <h1>{m.title}</h1>
            <pre className="maintenance-banner-pre">{m.content}</pre>
          </div>
        ))}
      </MaintenanceBanner>
    );
  }
}

export default connect(
  ({ maintenanceMessagesReducer }: AppState, ownProps: any) => ({
    ...ownProps,
    ...{ messages: maintenanceMessagesReducer.messages, isMessagesSeen: maintenanceMessagesReducer.isMessagesSeen },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...MaintenanceMessagesActions,
      },
      dispatch
    )
)(MaintenanceContainer);
