import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "../../store";
import { TextType } from "../../models/TextType";

export interface ITextState {
  about: string;
  aboutlexdania: string;
  informationsuppliers: string;
  contact: string;
  links: string;
}

// Actions
export enum TextActionEnum {
  RECEIVE_TEXT = "RECEIVE_TEXT",
}

//Action creators
const receiveText = (text: string, type: TextType) => createAction(TextActionEnum.RECEIVE_TEXT, { text, type });
const Actions = { receiveText };
type TextActions = ActionsUnion<typeof Actions>;

const getStaticFileName = (type: TextType): string => {
  switch (type) {
    case TextType.About:
      return "about.html";
    case TextType.AboutLexDania:
      return "aboutLexDania.html";
    case TextType.InformationSuppliers:
      return "informationSuppliers.html";
    case TextType.Contact:
      return "contact.html";
    case TextType.Links:
      return "links.html";
    default:
      return "";
  }
};

// Bound Action creators / Fetch
const getText = (type: TextType) => async (dispatch: any, getState: () => AppState) => {
  const text = getState().texts[type];
  if (text) {
    return;
  }
  const fileName = getStaticFileName(type);
  const textResult = await apiGET<string>(`/static/${fileName}`);
  dispatch(receiveText(textResult, type));
};

export interface ITextFetchers {
  getText(type: TextType): Promise<void>;
}

export const TextReducerActions = {
  getText,
};

//Reducer
const defaultState: ITextState = {
  about: "",
  aboutlexdania: "",
  informationsuppliers: "",
  contact: "",
  links: "",
};

export default (state = defaultState, action: TextActions): ITextState => {
  switch (action.type) {
    case TextActionEnum.RECEIVE_TEXT: {
      return { ...state, [action.payload.type]: action.payload.text };
    }
    default:
      return state;
  }
};
