import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "../../store";
import { IFooterLink } from "@lexdania/components";

export interface IFooterState {
  externalLinks: IExternalLinks;
}

export interface IExternalLinks {
  listLinks: IFooterLink[];
  wasLink: IFooterLink;
}

// Actions
export enum TextActionEnum {
  RECEIVE_EXTERNALLINKS = "RECEIVE_EXTERNALLINKS",
}

//Action creators
const receiveExternalLinks = (links: IExternalLinks) => createAction(TextActionEnum.RECEIVE_EXTERNALLINKS, links);
const Actions = { receiveExternalLinks };
type FooterActions = ActionsUnion<typeof Actions>;

// Bound Action creators / Fetch
const getExternalLinks = () => async (dispatch: any, getState: () => AppState) => {
  const links = getState().footer.externalLinks;
  if (links && links !== null && links.listLinks.length > 0) {
    return;
  }
  const result = await apiGET<IExternalLinks>("static/externalLinks.json");
  dispatch(receiveExternalLinks(result));
};

export interface IFooterFetchers {
  getExternalLinks(): Promise<void>;
}

export const FooterReducerActions = {
  getExternalLinks,
};

//Reducer
const defaultState: IFooterState = {
  externalLinks: { listLinks: [], wasLink: { url: "", text: "" } },
};

export default (state = defaultState, action: FooterActions): IFooterState => {
  switch (action.type) {
    case TextActionEnum.RECEIVE_EXTERNALLINKS: {
      return { ...state, externalLinks: action.payload };
    }
    default:
      return state;
  }
};
