import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./styles/_Site.scss";
import * as React from "react";
import { render } from "react-dom";
import configureStore from "./store";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";

const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <IntlProvider locale="da" defaultLocale="da">
      <BrowserRouter>{routes}</BrowserRouter>
    </IntlProvider>
  </Provider>
);

render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
